<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M22.63,14.88,15,22.51l-3.75.75L12,19.51l7.631-7.63a2.115,2.115,0,0,1,2.991,0l.009.009A2.115,2.115,0,0,1,22.63,14.88Z"
        />

        <line class="a" x1="5.249" y1="10.51" x2="14.249" y2="10.51" />

        <line class="a" x1="5.249" y1="14.26" x2="11.249" y2="14.26" />

        <line class="a" x1="5.249" y1="18.01" x2="8.249" y2="18.01" />

        <path
            class="a"
            d="M8.249,23.26h-6a1.5,1.5,0,0,1-1.5-1.5V6.01a1.5,1.5,0,0,1,1.5-1.5H6a3.75,3.75,0,0,1,7.5,0h3.75a1.5,1.5,0,0,1,1.5,1.5V8.26"
        />

        <path
            class="a"
            d="M9.749,3.76a.375.375,0,1,1-.375.375.374.374,0,0,1,.375-.375"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TaskListEditIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
