<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="17.25" cy="17.25" r="6" />
        <line class="a" x1="17.25" y1="14.25" x2="17.25" y2="20.25" />
        <line class="a" x1="17.25" y1="20.25" x2="15" y2="18" />
        <line class="a" x1="17.25" y1="20.25" x2="19.5" y2="18" />
        <line class="a" x1="3.75" y1="14.25" x2="8.25" y2="14.25" />
        <line class="a" x1="8.25" y1="14.25" x2="8.25" y2="6.75" />
        <line class="a" x1="11.25" y1="9.75" x2="11.25" y2="8.25" />
        <line class="a" x1="5.25" y1="14.25" x2="5.25" y2="9.75" />
        <path
            class="a"
            d="M7.5,20.25H2.25a1.5,1.5,0,0,1-1.5-1.5V2.25A1.5,1.5,0,0,1,2.25.75H12.879a1.5,1.5,0,0,1,1.06.439l2.872,2.872a1.5,1.5,0,0,1,.439,1.06V7.5"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ReportingIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
