<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="7.5" cy="7.875" r="4.125" />

        <path class="a" d="M.75,20.25a6.75,6.75,0,0,1,13.5,0" />

        <path class="a" d="M14.251,4.416a4.125,4.125,0,1,1-.006,6.914" />

        <path class="a" d="M15,13.667a6.757,6.757,0,0,1,8.25,6.583" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'SettingsSliderIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
