<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="7.5" y1="10.51" x2="16.5" y2="10.51" />

        <line class="a" x1="7.5" y1="14.26" x2="16.5" y2="14.26" />

        <line class="a" x1="7.5" y1="18.01" x2="16.5" y2="18.01" />

        <path
            class="a"
            d="M15.75,4.51H19.5A1.5,1.5,0,0,1,21,6.01V21.76a1.5,1.5,0,0,1-1.5,1.5H4.5A1.5,1.5,0,0,1,3,21.76V6.01a1.5,1.5,0,0,1,1.5-1.5H8.25a3.75,3.75,0,0,1,7.5,0Z"
        />

        <path
            class="a"
            d="M12,3.76a.375.375,0,1,1-.375.375A.375.375,0,0,1,12,3.76"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TaskListIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
