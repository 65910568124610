<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M12.5,11l.877-6.141A1,1,0,0,1,14.367,4H18.5a1,1,0,0,1,1,1v6"
        />

        <path class="a" d="M13.5,16a2,2,0,0,0,0,4h8a2,2,0,0,0,0-4" />

        <path class="a" d="M13.5,17.75a.25.25,0,0,1,.25.25" />

        <path class="a" d="M13.25,18a.25.25,0,0,1,.25-.25" />

        <path class="a" d="M13.5,18.25a.25.25,0,0,1-.25-.25" />

        <path class="a" d="M13.75,18a.25.25,0,0,1-.25.25" />

        <path class="a" d="M21.5,17.75a.25.25,0,0,1,.25.25" />

        <path class="a" d="M21.25,18a.25.25,0,0,1,.25-.25" />

        <path class="a" d="M21.5,18.25a.25.25,0,0,1-.25-.25" />

        <path class="a" d="M21.75,18a.25.25,0,0,1-.25.25" />

        <line class="a" x1="11.5" y1="15.848" x2="11.5" y2="10.864" />

        <path
            class="a"
            d="M3.338,10.5H7a1.109,1.109,0,0,1,.794.4L11.206,15.6A1.109,1.109,0,0,0,12,16H22.28a1,1,0,0,0,.981-1.2l-.6-3a1,1,0,0,0-.981-.8H12a1.239,1.239,0,0,1-.825-.38L8.825,7.88a.986.986,0,0,0-.818-.3L2.516,8.5"
        />

        <path class="a" d="M4.986,14.512A3.25,3.25,0,0,1,2.516,8.5Z" />

        <line class="a" x1="5" y1="14.5" x2="6" y2="14" />

        <line class="a" x1="16" y1="11" x2="17" y2="8" />

        <line class="a" x1="7.457" y1="10.628" x2="8.454" y2="7.637" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TripMultipleDestinationsIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
}
</style>
